.k-textInput {
  height: 40px;
  border: $border-slim $light-gray;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  max-width: 550px;

  &--error {
    border-color: $red;
    box-shadow: 0px 0px 20px -10px $red;
  }
  &--disabled {
    background: $lighter-gray;
    pointer-events: none;
  }
  &--centered {
    text-align: center;
  }

  &__label {
    width: 100%;
    font-size: $ts16;
    font-weight: 500;
    text-align: center;
    display: inline-block;
    margin-bottom: 8px;
  }
  &__labelText {
    font-size: 14px;
    line-height: initial;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  }
  &__errorMessage {
    font-weight: 500;
    text-align: center;
    display: inline-block;
    margin-top: 8px;
    width: 100%;
    color: $red;
    &--right {
      text-align: right;
    }
  }
  &__container {
    margin-bottom: 16px;
    overflow: hidden;
    &--short {
      width: 85%;
    }
    &--centeredLabel {
      .k-textInput__label {
        text-align: center;
      }
      .k-textInput__errorMessage {
        text-align: center;
      }
    }
  }
}

@include for-tablet-portrait-up {
  .k-textInput {
    &__label {
      margin-left: 1px;
      float: left;
      text-align: left;
      vertical-align: middle;
      font-size: $ts14;
      font-weight: 500;
      &--side {
        float: left;
        width: auto;
        line-height: 40px;
        margin-bottom: 0;
      }
    }
    &__errorMessage {
      font-size: $ts12;
      text-align: left;
      &--centered {
        text-align: center;
      }
    }
    &__container {
      &--side {
        .k-textInput__label {
          float: left;
          width: auto;
          line-height: 40px;
          margin-bottom: 0;
        }
        .k-textInput__errorMessage {
          float: right;
          width: 250px;
          text-align: center;
          &--right {
            text-align: right;
          }
        }
      }
    }
    &--side {
      width: 250px;
      float: right;
    }
    &--left {
      float: left;
      width: 250px;
    }
  }
  .errorMsg {
    font-size: $ts12;
    text-align: left;
  }
}

.errorMsg {
  font-weight: 500;
  text-align: center;
  display: inline-block;
  margin: 10px 0;
  width: 100%;
  color: $red;
}
