.k-topBarDuplicate,
.k-topBarClear {
  color: $dark-gray;
  text-decoration: underline;
  font-weight: 400;
}

.k-topBarClear {
  @include for-tablet-landscape-up {
    margin-right: 30px;
  }
}

.k-tabNav {
  flex-basis: 100%;
  width: 100%;

  &__dropdown {
    position: relative;
  }

  &__dropdownToggle {
    width: 100%;
    height: 40px;
    color: $black;

    &:active {
      background: $light-gray;
    }
  }

  &__dropdownMenu {
    display: none;
    position: absolute;
    z-index: 1000;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 100%;
    margin: 0;
    background: $white;
    padding: 0;

    &--open {
      display: block;
    }
  }

  &__dropdownElement {
    display: block;
    border-bottom: 1px solid $light-gray;
    text-align: center;
    line-height: 40px;
    color: $black;

    &:hover {
      color: $black;
      background: $light-gray;
    }
  }

  &__container {
    display: none;
  }

  &__route {
    width: 100%;

    &--active {
      background: $very-light-gray none repeat scroll 0 0;
      box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.1),
        0 10px 2px 0 $very-light-gray;
    }
  }

  &__routeLink {
    display: block;
    font-weight: 600;
    color: $black;
    text-align: center;
    height: 70px;
    position:relative;

    &:hover {
      color: $black;
    }
  }

  @include for-tablet-landscape-up {
    &__dropdown {
      display: none;
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: -10px -20px;
    }

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &__dropdown {
      margin: -20px -30px;
    }
  }

  @include for-desktop-up {
    &__container {
      margin: -10px -20px;
    }
  }

  .k-tabNav__label {
    display: inline-block;
    margin-top: 10px;
    margin-left: 0.5em;
    vertical-align: top;
    background-color: $blue;
  }
}
