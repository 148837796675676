/* BASIC SETUP */

* {
  outline: none !important;
}

body {
  font-family: $main-font;
  color: #454e57;
  font-size: $ts14;
  background: #272140;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}
.boldItalic {
  font-weight: 600;
  font-style: italic;
}

.clickable {
  cursor: pointer;
}

a:hover {
  color: $blue;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
  -webkit-transition: opacity 2s; /* Safari */
  transition: opacity 2s;
  &--gray {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 1;
  }
}
