.k-loginView {
  margin-top: 15px;

  .kmd-textInput {
    max-width: 450px;
  }

  &__welcomeMessage {
    font-size: 4.2rem;
    margin: 5vh 0;
    color: $orange;
  }

  &__loginButton {
    margin: 8rem 0 4rem;
    width: 250px;
  }

  &__forgotPassword {
    color: $gray;

    &:hover {
      text-decoration: underline;
    }
  }

  &__createAccount {
    color: $blue;

    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }
  }
  &__createAccountIntro {
    color: $gray;
    margin-right: 7px;
  }
  &__createAccountContainer {
    position: absolute;
    bottom: 25px;
    left: 3.5rem;
  }
}
