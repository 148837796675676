/*   FORMS   */
// validation
.form-error {
  margin-bottom: 20px;
  font-size: $ts12;
  width: 100%;
  text-align: center;
  color: rgb(198, 27, 4);
}

.input_error {
  border: 1px solid #f44336 !important;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(244, 67, 54, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(244, 67, 54, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(244, 67, 54, 0.75);
}

.input_correct {
  border: 1px solid #4caf50 !important;
  -webkit-box-shadow: 0px 0px 3px 0px #4caf50;
  -moz-box-shadow: 0px 0px 3px 0px #4caf50;
  box-shadow: 0px 0px 3px 0px #4caf50;
}

.input_error_message {
  color: #f44336;
}

@keyframes imp_input {
  0% {
    background: #fff;
  }

  50% {
    background: #ffeed8;
  }

  100% {
    background: #fff;
  }
}

.imp {
  border: 1px solid #06bbfe !important;
  animation-name: imp_input;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  text-align: center;
}

.imp_label {
  font-weight: 700 !important;
}

.del_link {
  color: #d0021b;
  display: inline-block;
  font-size: $ts14 !important;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.set_for_all {
  color: #454e57;
  cursor: pointer;
  display: block;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  &:hover {
    color: #06bbfe !important;
  }
}

.form_right {
  display: block;
  width: 250px;
  float: right;
}

.addAvaType .form_right {
  display: block;
  width: auto;
  float: right;
}

.low_label {
  line-height: 30px !important;
}

.switch_label_right {
  display: inline-block;
  line-height: 40px;
  vertical-align: text-bottom;
}

.form_right .switch-big {
  vertical-align: bottom;
}

.small_comment {
  font-size: $ts10;
  margin: 10px 0;
}

.small_comment_or {
  font-size: $ts10;
  margin: -10px 0 10px;
  text-align: center;
}

.small_comment.under {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
}

.add_other {
  color: #a2a6ab;
  display: block;
  float: right;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #06bbfe;
  }
}

.form-group.multi {
  background: #f7f9fb none repeat scroll 0 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin: 0 -20px 10px;
  padding: 0;
}

.multi_item {
  display: block;
  min-height: 60px;
  padding: 10px 20px;
  &:nth-child(2n) {
    background: #eff3f7;
  }
  > label {
    align-items: center;
    display: inline-flex;
    font-size: $ts12;
    min-height: 40px;
    margin: 0 20px 0 0;
    width: 100px;
  }
}

.kmodal .multi input {
  float: left;
  min-width: 100px;
  width: 130px;
}

.multi .form_right {
  width: 250px;
  .close {
    font-size: $ts18;
    line-height: 40px !important;
    margin: 0 12px 0 0;
  }
}

.half_input {
  float: left !important;
  min-width: 0 !important;
  width: 48% !important;
  &:first-child {
    margin-right: 4%;
  }
}
.custom_rate_stawka {
  line-height: 40px;
  margin: 0 20px;
}

.labelTitle{
  font-size: 14px;
  color: $dark-gray;
  font-weight: 600;
  line-height:16px;
}

.labelDesc{
  font-size: 12px;
  opacity: 0.8;
  line-height: 15px;
}