/* WRAPPERS */

#wrapper {
  position: relative;
}

#page-wrapper {
  padding: 0px;
  min-height: 1200px;
  position: relative !important;
  margin: 0 0 0 60px;
  background: #f7f9fb;
}

/* PANELS*/

.panel-blank {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none !important;
  box-shadow: none !important;
  .table-responsive {
    padding-bottom: 5px;
  }
}

.panel-body {
  background: #fff;
  border-radius: 3px;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ced7df;
  &.panel-nopad {
    padding: 0px;
  }
}

.panel-title {
  //  UNUSED - TRY TO DELETE
  border-bottom: 2px solid #cdd6de;
  color: #454e57;
  font-size: $ts21;
  font-weight: 600 !important;
  line-height: 26px !important;
  margin: 0;
  min-width: 240px;
  padding: 11px 30px 13px;
  vertical-align: top;
  width: 100%;
}

// DROPDOWN-MENU
.dropdown-menu {
  border: 1px solid #cdd6de;
  border-radius: 0;
  display: none;
  float: left;
  font-size: $ts12;
  left: 0;
  list-style: outside none none;
  margin-top: 14px;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 10px;
  line-height: 20px;
  .checkbox {
    margin: 0 0 10px;
  }
  li:last-child .checkbox {
    margin: 0px;
  }
  .checkbox label {
    font-size: $ts13;
    line-height: 20px;
    padding-left: 10px;
    height: 22px;
  }
  .divider {
    margin: 2px 0 12px;
  }
  .material-icons {
    font-size: $ts21;
    margin-right: 18px;
  }
  .half {
    width: 50%;
    float: left;
  }
}

/* Switch Toggle */

.swichbox {
  display: inline-block;
}

.switch-small {
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
}

.toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
  + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }
}

input.toggle-small {
  + label {
    height: 18px;
    padding: 0px;
    transition: background 0.4s ease 0s;
    width: 25px;
    border-radius: 20px;
    margin: 0;
    &:before,
    &:after {
      display: block;
      position: absolute;
      content: "";
    }
    &:before {
      top: 8px;
      left: 2px;
      bottom: 2px;
      right: 2px;
      height: 2px;
      background-color: #ced7df;
      transition: background 0.4s;
    }
    &::after {
      background-color: #06bbfe;
      border-radius: 20px;
      bottom: 4px;
      left: 2px;
      top: 4px;
      transition: margin 0.4s ease 0s, background 0.4s ease 0s;
      width: 10px;
    }
  }
  &:checked + label:after {
    margin-left: 10px;
  }
  &:disabled + label:after {
    background-color: #ced7df;
  }
}

/* Switch Toggle big */

.swichbox {
  display: inline-block;
  &.period {
    display: block;
    float: right;
  }
}

.swichbox.disabled {
  > span {
    color: #ced7df;
  }
}

.switch-big {
  display: inline-block;
  margin: 0 5px 0 0;
  vertical-align: sub;
}

.toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
  + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }
}

input.toggle-big {
  + label {
    padding: 2px;
    width: 57px;
    height: 34px;
    background-color: #ced7df;
    border-radius: 60px;
    transition: background 0.4s;
    &:before,
    &:after {
      display: block;
      position: absolute;
      content: "";
    }
    &:before {
      top: 1px;
      left: 1px;
      bottom: 1px;
      right: 1px;
      background-color: #fff;
      border-radius: 60px;
      transition: background 0.4s;
    }
    &::after {
      top: 5px;
      left: 5px;
      bottom: 5px;
      width: 24px;
      background-color: #ced7df;
      border-radius: 52px;
      transition: margin 0.4s, background 0.4s;
    }
  }
  &:checked + label:after {
    margin-left: 23px;
    background-color: #06bbfe;
  }
}

/* Search */

.form-search {
  display: inline-block;
  float: right;
  position: relative;
  label {
    color: #4a4a4a;
    font-size: $ts14;
    line-height: 17px;
    margin-right: 12px;
    font-weight: 400;
  }
  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #e7eaec;
    font-size: $ts14;
    line-height: 17px;
    outline: none;
    padding: 0 20px 5px 0;
    min-width: 280px;
    font-style: italic;
  }
}

.form-search_button {
  color: #06bbfe;
  font-size: $ts16;
  position: absolute;
  right: 1px;
  text-decoration: none;
  top: -3px;
  cursor: pointer;
}

/* PAYMENT */

.pay_blue {
  background-color: #06bbfe;
  border: 1px solid #06bbfe;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  cursor: pointer;
  font-size: $ts14;
  font-weight: 700;
  height: 30px;
  line-height: 20px;
  margin: 0 auto;
  padding: 4px 27px;
  text-align: center;
  width: 70%;
  max-width: 150px;
  &:hover {
    background-color: #3aa8da;
    color: #fff;
  }
}

.table_payment td {
  line-height: 24px;
  text-align: center;
  vertical-align: middle !important;
}

.emp_lim {
  color: #dedede;
}

.sub_th th {
  border-bottom: medium none !important;
  font-weight: 700 !important;
}

.brutto_pay {
  color: #06bbfe;
  font-weight: 600;
}

.payed {
  color: #2ec23e;
}

.table_payment th {
  &.kw_netto,
  &.kw_brutto,
  &.kw_netto,
  &.ilu_pracownikow,
  &.termin,
  &.data_pay,
  &.plan,
  &.detal {
    min-width: 180px;
    width: 17% !important;
    padding: 7px 20px;
  }
  &.opcje_pay {
    min-width: 180px;
    padding: 7px 20px;
    width: 15% !important;
  }
}

.pay_detal {
  font-weight: 700 !important;
  padding: 0 20px !important;
  width: 17%;
}

.pay_adres {
  padding: 20px !important;
}

.pay_mtd {
  padding: 20px !important;
  vertical-align: top !important;
}

.table_payment th {
  &.pay_kwota,
  &.pay_dane {
    min-width: 180px;
    width: 20% !important;
    padding: 7px 20px;
  }
  &.pay_oplac {
    min-width: 180px;
    width: 60% !important;
    padding: 7px 20px;
  }
}

.payment_mtd {
  font-weight: 700;
}

fieldset .table {
  border: medium none;
  box-shadow: none;
}

/* Eventy */

.sch_block {
  &.ev_imp {
    background: #e8808d;
  }
  &.ev_note {
    background: #f8eab4;
  }
  &.ev_imp .time {
    line-height: 25px;
  }
  &.ev_note .time {
    line-height: 25px;
    color: #454e57;
  }
}

.ev_ico {
  font-size: $ts12 !important;
  position: absolute;
  right: 4px;
  top: 4px;
}

.event_label {
  display: inline-block;
  height: 12px;
  margin-right: 5px;
  width: 12px;
  &.prio {
    background: #e8808d;
  }
  &.note {
    background: #f8eab4;
  }
}

/* Radio */

.radio {
  cursor: pointer;
  input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
  }
  label {
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #cdd6de;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out;
    }
    &::after {
      display: inline-block;
      position: absolute;
      content: " ";
      width: 6px;
      height: 6px;
      left: 7px;
      top: 7px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #555555;
      -webkit-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s
        cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }
  }
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
}

.radio {
  padding-left: 20px;
  margin-bottom: 14px;
  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 20px;
  }
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #cdd6de;
}

.form-control:focus {
  border-color: #06bbfe !important;
}

/* Checkbox */

.checkbox {
  padding-left: 20px;
  label {
    display: inline-block;
    padding-left: 0px;
    position: relative;
    vertical-align: middle;
    &::before {
      background-color: #fff;
      border: 1px solid #cdd6de;
      border-radius: 3px;
      content: "";
      display: inline-block;
      height: 20px;
      left: 0;
      margin-left: -20px;
      position: absolute;
      transition: border 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;
      width: 20px;
    }
    &::after {
      color: #555555;
      display: inline-block;
      font-size: $ts13;
      height: 19px;
      left: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      position: absolute;
      top: 0;
      width: 19px;
      line-height: 19px;
    }
  }
  input[type="checkbox"] {
    &:checked + label::after {
      font-family: $icons-font;
      content: "check";
      color: #06bbfe;
      font-weight: bolder;
      font-size: $ts16;
      margin-left: -21px;
    }
    cursor: pointer;
    height: 24px;
    margin-top: 0;
    opacity: 0;
    width: 20px;
    z-index: 1;
  }
  input[type="checkbox"]:disabled {
    cursor: not-allowed;

    + label:before {
      background-color: #eaeaea;
    }
    + label:after {
      content: '';
    }
  }
}

/* Tooltip */

.tooltip {
  font-size: $ts12;
  line-height: 12px;
  > .tooltip-inner {
    border-radius: 0;
    padding: 7px 12px;
  }
}

/* Range */

input[type="range"] {
  -webkit-appearance: none;
  width: 160px;
  height: 43px;
  float: left;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 160px;
    height: 34px;
    cursor: pointer;
    animate: 0.2s;
    background: #fff;
    border-radius: 30px;
    border: 1px solid #ced7df;
    margin: 8px 0 !important;
    padding: 0 5px;
  }
  &::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    background: #06bbfe;
    cursor: pointer;
    border: none;
    -webkit-appearance: none;
    margin-top: 4px;
  }
  &::-moz-range-track {
    width: 160px;
    height: 32px;
    cursor: pointer;
    animate: 0.2s;
    background: #fff;
    border-radius: 30px;
    border: 1px solid #ced7df;
    padding: 0px 4px;
  }
  &::-moz-range-thumb {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    border: none;
    background: #06bbfe;
    cursor: pointer;
  }
  &::-ms-track {
    width: 160px;
    height: 32px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border: 1px solid #ced7df;
    border-radius: 30px;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: #2a6495;
    border: 1px solid #ced7df;
    border-radius: 30px;
  }
  &::-ms-fill-upper {
    background: #3071a9;
    border: 1px solid #ced7df;
    border-radius: 30px;
  }
  &::-ms-thumb {
    height: 24px;
    width: 24x;
    border-radius: 24px;
    background: #06bbfe;
    cursor: pointer;
  }
  &:focus {
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      background: #fff;
    }
  }
}

.range-value {
  display: block;
  float: right;
  line-height: 50px;
  text-align: left;
  width: 100px;
}

//DRAGABLE
.drag_handle {
  color: #ced7df;
  cursor: move;
  line-height: 30px !important;
  margin: 0 24px 0 0;
  vertical-align: bottom;
}

/* Scrollbary */

.table-responsive::-webkit-scrollbar {
  width: 16px;
  background-color: rgba(0, 0, 0, 0.1);
}
.table-responsive::-webkit-scrollbar-thumb {
  width: 20px;
  background: #06bbfe;
}
.table-responsive::-webkit-scrollbar:horizontal {
  height: 17px;
  background-color: rgba(0, 0, 0, 0.1);
}
.table-responsive::-webkit-scrollbar-thumb:horizontal {
  width: 20px;
  background: #06bbfe;
}
.table-responsive::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.1);
}

//REACT S ALERT ANIMATIONS

/*SCALE*/

.s-alert-effect-scale a {
  color: #fff;
}

.s-alert-effect-scale a:hover,
.s-alert-effect-scale a:focus {
  color: #fff;
}

.s-alert-effect-scale .s-alert-close::before,
.s-alert-effect-scale .s-alert-close::after {
  background: #fff;
}

.s-alert-effect-scale .s-alert-close:hover::before,
.s-alert-effect-scale .s-alert-close:hover::after {
  background: #fff;
}

.s-alert-effect-scale.s-alert-show,
.s-alert-effect-scale.s-alert-hide {
  -webkit-animation-name: animScale;
  animation-name: animScale;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

@-webkit-keyframes animScale {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

@keyframes animScale {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
    transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

//MASKED INPUT SUGGESTIOSN
.mask-time-input {
  padding: 5px 80px;
}
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: $secondary-font;
  font-weight: 300;
  font-size: $ts16;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.suggestion_input:focus {
  outline: none;
}

.suggestion_container--open .suggestion_input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.suggestion_container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: $ts16;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  height: 150px;
  overflow: auto;
  width: 100%;
}

.suggestion {
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
}
.suggestion-selected {
  background-color: #ededed;
}
.suggestion:hover {
  background-color: #ededed;
}
.suggestion--focused {
  background-color: #ddd;
}
.masked-input-container {
  max-width: 250px;
  height: auto;
  float: right;
}

#kalendarz.popover {
  max-width: none;
}
.Cal__Header__date {
  font-size: $ts21;
}

#intercom-container .intercom-launcher-frame {
  width: 65px !important;
  height: 65px !important;
}

.custom_rate_ico {
  color: #06bbfe;
  font-size: 25px !important;
  line-height: 20px !important;
  margin: -4px 0 0 -30px;
  position: absolute;
}

// Arrow in select input used in add employee modal

.select_open {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: none;
}
