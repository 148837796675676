@media (max-width: 1675px) {
  .drag_handle,
  .avatar,
  .table {
    &.budget th.tab_title,
    &.schedule th.tab_title {
      min-width: 180px;
    }
    &.budget .col_name {
      padding-left: 20px !important;
    }
  }
  .daydate {
    display: block;
  }
  .table {
    &.budget th,
    &.schedule th {
      min-width: 100px;
    }
  }

  .table.availability th {
    min-width: 48px;
  }
  .search_cont {
    left: 30px;
  }
  .check_all,
  .selected_act {
    right: 30px;
  }
  .fs-left {
    margin: 0 0 10px 0px;
    width: 500px;
    text-align: center;
  }
  .fs-right {
    margin: 0 0 10px 0px;
    width: 100%;
    text-align: center;
    .option label {
      line-height: 20px;
      margin-top: 20px;
      text-align: center;
      width: 100%;
    }
  }
  .fs-left .option label {
    line-height: 20px;
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 1400px) {
  .panel-setting fieldset {
    padding: 30px;
  }
  .option {
    height: auto;
    .switch-big {
      float: none;
    }
    .select-styled {
      float: none;
      margin: 0 auto;
      width: 250px;
    }
    .range-big {
      height: auto;
      margin: 0;
      width: 100%;
      min-width: 100px;
    }
    .switch-big label,
    .range-big label {
      width: auto;
      margin: 0;
    }
  }
  input[type='range'] {
    width: 100%;
  }
  .range-value {
    float: none;
    line-height: 20px;
    text-align: center;
    width: 100%;
  }
  .save_settings {
    float: none;
  }
  .option {
    .domena {
      line-height: 30px;
      position: relative;
      right: 0;
    }
    input {
      &[type='password'],
      &[type='text'] {
        min-width: 100px;
        width: 100%;
      }
    }
  }
  .pass_remind {
    margin: 10px 0 35px;
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .toggleContainer {
    float: right !important;
  }
  .minuteHourInputContainer {
    float: right !important;
  }
}
@media (max-width: 1023px) {
  h1.page_title {
    padding-left: 30px;
  }
  .topbar,
  .funbar1 {
    height: auto;
  }
  .functionbar {
    height: auto;
    padding: 0 30px;
  }
  .funbar1l,
  .funbar1r {
    display: block;
    float: none;
  }
  .funbar1l {
    padding: 0 0 11px;
  }
  #page-wrapper {
    margin: 0 0 0 60px;
  }
  .publikuj {
    width: 90px;
  }
  .change_box {
    padding: 0 20px;
  }
  .export {
    right: 30px;
  }
  .taby.nav-tabs li {
    width: auto;
    a,
    &:hover a {
      padding: 10px;
    }
    a {
      &:hover,
      &:focus {
        padding: 10px;
      }
    }
  }
  .nav-tabs > li > a i {
    display: block;
    height: 24px;
    line-height: 22px;
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
  .play-pause-stop {
    margin-top: 10vh;
    display: block;
  }
  .nav-tabs > li > a i {
    margin-bottom: 5px;
  }
  .subheadbar-wrapper {
    padding: 0;
  }
  .settingsRow {
    $errorColor: #ff0101;
    input {
      width: 30%;
      width: calc(100% - 300px);
      min-width: 100px;
    }
    span.error {
      width: 100%;
      width: calc(100% - 120px);
      text-align: center;
      float: right;
    }
    label {
      padding: 0 10px;
    }
  }
  .importStoryContainer {
    .arrow {
      display: none;
    }
    .step {
      .stepNumber {
        display: inline-block;
      }
    }
  }
}

@media (max-height: 800px) {
  .nav > li {
    > a {
      padding: 10px 20px 10px 19px;
    }
    &.active > a {
      padding: 10x 20px 10px 15px;
    }
  }
}

@media (max-width: 680px) {
  .toggleContainer {
    float: left !important;
  }
  .minuteHourInputContainer {
    float: left !important;
  }
  .swichbox.period {
    float: none;
    text-align: center;
  }

  .calendar-view-container {
    display: block !important;
  }

  .intercom-launcher-frame {
    display: none !important;
  }
  .popover .arrow {
    display: none;
  }
  #colorPop {
    position: fixed;
    margin: 150px calc(50vw - 71px);
    top: 0 !important;
    left: 0 !important;
  }
  .taby {
    display: none;
  }
  .functionbar.under {
    height: 38px !important;
  }
  .titlebar {
    display: none;
  }
  #attlist .wptime {
    &:nth-child(odd) {
      display: none;
    }
    margin-left: 8.3333%;
  }
  .mobile_menu {
    float: right;
    padding: 25px 15px 0 0;
    display: block;
    i {
      color: #06bbfe;
      cursor: pointer;
    }
  }
  .work-form-password {
    width: 80%;
  }
  .nav-header {
    width: 100% !important;
  }
  #page-wrapper {
    margin: 70px 0 0 0px;
  }

  .nav {
    &.nav-tabs > li:first-child {
      width: 33.3333%;
    }
    >  {
      li {
        &:nth-of-type(11),
        &:nth-of-type(12) {
          width: 50%;
        }
      }
      br {
        display: none;
      }
      li {
        > a i {
          display: block;
          height: 24px;
          line-height: 22px;
          margin-right: 0;
          text-align: center;
          width: 100%;
        }
        &.active {
          border: none;
        }
      }
    }
  }
  .sidebar-collapse .nav > li {
    border: none;
    border-bottom: $border-thick #272140;
    &.active {
      background: #0a0322 none repeat scroll 0 0;
      border: none;
      border-bottom: $border-thick #ff6b01;
    }
  }
  .nav-label {
    font-size: $ts10;
  }
  .account_avatar {
    float: left;
    width: 20%;
  }
  .user_details {
    float: right;
    text-align: left;
    width: 80%;
  }
  .m-t-xs {
    margin: 0;
  }
  .titlebar {
    height: auto;
  }
  h1.page_title {
    padding-left: 30px;
    display: block;
  }
  .change_box {
    display: block;
    float: left;
    margin: 10px 0;
    padding: 0 10px 0 0;
    border: none;
    text-align: left;
    height: 39px;
  }
  .title_right {
    display: block;
    height: 60px;
    float: none;
    padding: 0 0 0 30px;
    width: 100%;
    border-top: $border-slim #cdd6de;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .change_box a {
    display: inline-block;
    width: 100%;
  }
  .publikuj {
    height: 60px;
  }
  .cal_switch {
    display: block;
    margin: 0;
    padding: 11px 0;
    text-align: center;
    width: 100%;
  }
  .funbar1l > a,
  .funbar1r > a {
    display: block;
    float: none;
    text-align: center;
    width: 100%;
  }
  .clearview {
    margin: 0 0 10px;
  }
  .funbar2 {
    height: auto;
  }
  .filter_cont {
    display: block;
    margin: 0 0 10px;
    width: 100%;
    text-align: center;
    &:last-child {
      margin: 0px;
    }
  }
  .fun_filter {
    margin: 0 0px 0 10px;
  }
  .del_long {
    display: none;
  }
  .user_details {
    opacity: 1;
    transform: scale(1);
  }
  .account_avatar {
    display: none;
  }
  .user_details {
    width: 100%;
  }
  .taby.nav-tabs li {
    width: 100%;
    &.active {
      background: #f7f9fb none repeat scroll 0 0;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) inset !important;
      z-index: 0;
    }
  }
  .functionbar.under {
    height: auto;
    padding: 10px 30px 10px;
    text-align: center;
    .swichbox {
      display: block;
      padding: 0 0 30px;
      text-align: center;
      width: 100%;
    }
  }
  .form-search {
    display: inline-block;
    float: none;
    margin: 0 auto;
    position: relative;
  }
  .form-search input {
    min-width: 60vw;
  }
  .delete_selected,
  .add_blue {
    width: 100%;
    margin-bottom: 20px;
  }
  .found_rekord {
    left: 0;
    right: 0;
    position: relative;
    top: 0;
    margin: 0 0 20px;
    text-align: center;
  }
  .no_notif {
    font-size: $ts16;
    i {
      font-size: 40px;
    }
  }
  .option .image {
    width: 100%;
    text-align: center;
    div.setavatar {
      float: none;
      margin: 0 auto;
    }
  }
  .del_profile,
  .change_img {
    float: none;
    line-height: 50px;
  }
  .option.imgs label {
    margin: 20px 0;
  }
  .form_right {
    width: 100%;
  }
  .table-responsive {
    margin-bottom: 0;
  }
  .settingsRow {
    label {
      padding: 0 10px;
      width: 100%;
      text-align: center;
    }
    input.inputError {
      width: 80%;
      width: calc(100% - 70px);
    }
    input {
      width: 95%;
    }
    span.error {
      width: 100%;
    }
  }
  .settingsButton {
    margin: 20px auto;
  }
}
@media (max-width: 350px) {
  .form-search input {
    min-width: 30vw;
  }
}
