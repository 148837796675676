.kadroGrid {
  margin-bottom: 15px; // 15px margin after grid
  position: relative;
  perspective: 500px; // prevents glitches when scrolling
  // No bootstrap styles so for ease of use i had to add some styles for tags
  table {
    table-layout: fixed;
    border: none;
    background: $white;
    margin: 0;
    position: relative;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }
  td {
    background-clip: padding-box;
    border-bottom: $table-border;
    border-left: $table-border;
    padding: 0 7px;
    height: 54px;
    position: relative;
    vertical-align: top;
    line-height: 1.428;
    font-weight: 400;
    &.center {
      text-align: center;
      vertical-align: middle;
      padding: 0;
    }
    &.middle {
      vertical-align: middle;
    }
    .checkbox {
      margin: 0 15px;
    }
    &:nth-child(1) {
      border-left: none;
    }
    &.ellipsis {
      text-overflow: ellipsis;
    }
  }
  th {
    height: 60px;
    min-height: 60px;
    line-height: 1.428;
    padding: 7px;
    border-bottom: $table-border;
    border-left: $table-border;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
    &.tab_title {
      border-left: none;
      color: $dark-gray;
      font-size: $ts21;
      line-height: 30px;
      padding-left: 24px;
      text-align: left;
    }
    &:nth-child(1) {
      border-left: none;
    }
    &.graph {
      background: $very-light-gray;
      padding: 0;
      border-left: none;
    }
    &.scheduleHeader {
      padding: 0;
      position: relative;
    }
    &.scheduleHeader:hover .k-ManagerNoteCorn {
      display: block;
    }
  }
  &__contentContainer {
    position: relative;
  }
  &__rowsContainer {
    overflow: hidden;
  }
  &__component {
    border: $table-border;
    margin-bottom: 0;
  }
  &__leftHeader {
    float: left;
    padding: 0;
    border-bottom: 0;
    tr {
      border: $table-border;
    }
    tr.empty {
      border: transparent;
      background: $very-light-gray;
    }
  }

  &__header {
    overflow: hidden;
    border-bottom: 0px;
    min-height: 62px;
    &.graph {
      margin-top: 30px;
    }
    tr {
      border: $table-border;
    }
    tr.empty {
      border-color: transparent;
    }
  }
  &__leftColumn {
    @extend .kadroGrid__component;
    float: left;
    box-shadow: none;
    overflow: hidden;
    border-top: none;
    td:nth-child(1) {
      vertical-align: middle;
    }
    &.empty {
      border-right: none;
    }
  }
  &__rows {
    @extend .kadroGrid__component;
    overflow: hidden;
    width: 100%;
    border-top: 0px;
    &--hidden {
      visibility: hidden;
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    &--disabled .kadroGrid__disabledEditOverlay {
      display: block;
    }
  }

  &__disabledEditOverlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba(200, 200, 200, 0.5);
    width: 466px;
    border-right: 3px solid $gray;
    z-index: 110;

    &--full {
      border-right: none;

      .kadroGrid__disabledEditContent {
        left: 50%;
        transform: translate(-50%, -50%);
        writing-mode: unset;

        .k-icon {
          display: block;
          font-size: 45px;
          transform: unset;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }

    &--small {
      .kadroGrid__disabledEditContent {
        left: 50%;
        transform: translate(0, -50%);

        .k-icon {
          display: block;
          font-size: 45px;
          transform: unset;
        }

        > span {
          display: none;
        }
      }
    }
  }

  &__disabledEditContent {
    display: inline-block;
    position: sticky;
    top: 50%;
    left: 100%;
    transform: rotateZ(-180deg) translateY(50%);
    text-transform: uppercase;
    writing-mode: vertical-rl;
    font-size: 18px;
    user-select: none;
    cursor: initial;

    .k-icon {
      display: inline-block;
      transform: rotateZ(-270deg);
    }

    > span {
      margin-top: 10px;
    }

    .availability & {
      display: inline-flex;
      justify-content: center;
      align-items: flex-end;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -30%);

      .k-icon {
        margin-bottom: 0;
        font-size: 35px;
      }

      > span {
        margin-top: 0;
      }
    }
  }

  &--new {
    th, tr, td,
    .kadroGrid__rows {
      border: 0;
      color: $dark-purple;
      text-align: left;
    }

    th {
      font-size: 20px;
      padding-left: 20px;
    }

    td {
      padding-left: 20px;
      height: 65px;
    }

    tr:nth-of-type(even) {
      background-color: $very-light-gray;
    }

    .sortdir {
      vertical-align: sub;
      font-size: 28px;
    }
  }

  &--notScrolled {
    .kadroGrid__rowsContainer,
    .kadroGrid__rows {
      overflow: visible;
    }
  }
}

.row {
  &--loading {
    background: $lighter-gray;
  }
}
