/* ERROR & LOGIN & LOCKSCREEN*/

.middle-box {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
}

.lockscreen.middle-box {
  width: 200px;
  padding-top: 110px;
}

.middle-box h1 {
  font-size: 170px;
}

.wrapper .middle-box {
  margin-top: 140px;
}
