.topbar {
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px #cdd6de;
}
//TITLE

.titlebar {
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px #cdd6de;
}

h1.page_title {
  font-size: $ts24;
  font-weight: 700;
  margin: 0;
  padding: 24px 0 26px 50px;
  display: inline-block;
  float: left;
}

.tithelp {
  color: #06bbfe;
  cursor: pointer;
  margin-left: 15px;
  vertical-align: middle;
  &:hover {
    color: #3aa8da;
  }
}

.title_right {
  float: right;
}

//SCHEDULE CREATION SPECYFIC

.view_mode {
  position: absolute;
  left: 50px;
  top: 20px;
}

.change_box {
  border-left: 2px solid #b8c0c8;
  display: flex;
  flex-direction: column;
  float: right;
  height: 60px;
  justify-content: center;
  margin: 10px 0;
  padding: 0 40px;
  text-align: right;
}

.publikuj {
  background: #ff6b01;
  float: right;
  height: 80px;
  width: 150px;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #e56000;
  }
  > span {
    color: #fff;
    font-size: $ts14;
    font-weight: 700;
    margin-top: 7px;
  }
  .send {
    color: #fff;
    line-height: 18px;
  }
}

//FUNCTIONBAR

.functionbar {
  height: auto;
  padding: 0 50px;
  &.under {
    height: 38px;
    padding: 10px 50px 10px 50px;
  }
  a:hover {
    color: #06bbfe !important;
  }
}

.funbar1 {
  height: 39px;
  font-size: $ts12;
  line-height: 14px;
  padding: 11px 0;
}

.funbar1l {
  display: inline-block;
}

.funbar1r {
  display: inline-block;
  float: right;
}

.funbar1 a,
.funbar2 a {
  color: #454e57;
  text-decoration: underline;
  font-weight: 700;
}

.change_box a,
.clearview,
.duplicateprev {
  color: #454e57;
  text-decoration: underline;
  font-weight: 400 !important;
}

.funbar2 {
  height: 39px;
  font-size: $ts12;
  line-height: 14px;
  padding: 11px 0;
  border-top: 2px solid #cdd6de;
}

.cal_switch {
  display: inline-block;
  margin: 0 60px;
}

.cal_date > a {
  margin: 0 15px;
}

.filter_cont {
  position: relative;
  display: inline-block;
}

.clearview {
  margin-right: 30px;
}

.clearview:hover,
.duplicateprev:hover,
.fun_filter > span:hover,
.change_box a:hover {
  color: #06bbfe !important;
}

.fun_filter {
  display: inline;
  margin: 0 30px 0 10px;
  cursor: pointer;
  > span {
    color: #454e57;
    text-decoration: underline;
    margin-right: 10px;
    font-weight: 700;
  }
}

/* Nav tabs */

.taby.nav-tabs {
  border: none;
  height: 38px;
  li {
    height: 38px;
    text-align: center;
    &.active {
      background: #f7f9fb none repeat scroll 0 0;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) inset, 0 10px 2px 0 #f7f9fb;
      z-index: 0;
    }
    a,
    &:hover a {
      background: none;
      border: none;
      color: #4a4a4a !important;
      height: 38px;
      line-height: 17px;
      padding: 10px 50px 10px 50px;
    }
    a {
      &:hover,
      &:focus {
        background: none;
        border: none;
        color: #4a4a4a !important;
        height: 38px;
        line-height: 17px;
        padding: 10px 50px 10px 50px;
      }
    }
    &.active a {
      border: none;
    }
  }
}
