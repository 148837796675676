.k-errorBoundary {
  text-align: center;
  padding: 10vh 20px 0;

  &__mainText {
    font-size: 32px;
    margin-top: 10vh;
  }

  &__subText {
    font-size: 18px;
    white-space: pre-wrap;
    margin: 25px 0 35px;
  }
  
  &__contactText {
    font-size: 16px;
  }
  
  &__email {
    margin: 0;
  }
  
  &__phone {
    margin: 0;
  }

  &__refresh {
    width: 500px;
    margin: 50px auto 0;
  }
}
