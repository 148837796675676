.k-wrapper {
  position: relative;
  padding: 5px;
  &--rightScroll {
    padding-right: 20px;
  }
  @include for-tablet-portrait-up {
    padding: 15px;
  }
  @include for-tablet-landscape-up {
    padding: 30px 50px 60px;
    &--smallPadding {
      padding: 0 20px;
    }
    &--tablePadding {
      padding: 7px 15px;
    }
    &--searchBar {
      padding-top: 5px;
    }
  }
}
