.compareWidget{
  &__valueContainer{
    width: auto;
    height: 68px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right:20px;;

  }
  &__values{
    float: left;
    margin-right: 15px;
    text-align:center;
  }
  &__upperValue, &__lowerValue{
    font-size: $ts24;
    font-weight: 300;
  }
  &__upperValue{
    border-bottom: 1px solid $light-gray;
  }
  &__percent{
    font-size: $ts14;
    font-weight: 300;
    color: $darker-gray;
    float: right;
    position: relative;
    font-size: $ts36;
    margin-top: 10px;
    height: 50px;
    border-left: 2px solid $darker-gray;
    padding-left: 10px;
    &:after{
      content: '';
   position: absolute;
   bottom: -1px;
   left: -6px;
   width: 10px;
   height: 10px;
   border-left: 2px solid $green;
   border-bottom: 2px solid $green;
   transform: rotate(-45deg);

    }
    &--green{
      color: $green;
      border-left: 2px solid $green;
    }
    &--red{
      color: $red;
      border-left: 2px solid $red;
      &.compareWidget__percent:after{
      border-color: $red;
      }
    }
    &--up{
      &:after{
        left: -6px;
        top: 0;
        font-size: 19px; // it's not text...
        border-left: none;
        border-bottom: none;
        border-right: 2px solid $green;
        border-top: 2px solid $green;
      }
    }
  }
}

.widget{
  &__titleContainer{
    height: 50px;
    display: inline-block;
  }
  &__title{
    font-size: $ts18;
    font-weight: 600;
  }
  &__subtitle{
    font-size: $ts16;
    font-weight: 500;
    color: $gray;
  }
  &__bigValue{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto 0;
    font-size: $ts24;
    font-weight: 600;
    color: $darker-gray;
    height: 34px;
    &--green{
      color: $green;
    }
    &--orange{
      color: $orange;
    }
    &--red{
      color: $red;
    }
    &--blue{
      color: $blue;
    }
    &--yellow{
      color: $yellow;
    }
  }
  &__value{
    font-size: $ts36;
    font-weight: 300;
    float: right;
    margin-right: 100px;
  }
  &__compareHours {
    width: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }

  &__compareHoursElement {
    display: flex;
    justify-content: space-between;
    font-size: $ts16;
    font-weight: 300;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
    }

    > span:first-child {
      margin-right: 10px;
    }
  }
}

.k-fadedIcon {
  position: absolute;
  bottom: -20px;
  right: 0px;
  overflow: hidden;
  width: 100px;
  height: 100px;

  i {
    position: absolute;
    top: -10px;
    font-size: 120px;
    color: $blue;
    opacity: 0.5;
  }
}

@media (max-width: 1300px) {
  .compareWidget{
    &__valueContainer{
      top:auto;
      bottom: 20px;
      left: 0;
      right:0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
  .widget{
    &__titleContainer{
      width: 100%;
      text-align: center;
      margin-bottom: 80px;
    }
    &__value{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      margin: 0 auto;
      margin-right: auto;
      text-align: center;
    }
    &__compareHours {
      transform: none;
      bottom: 20px;
      left: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    &__compareHoursElement {
      width: 100%;
    }
  }
}
