.k-topBarDateMode {
  display: flex;
  margin: 0 .9rem;

  &__mode {
    border: 1px solid $border-color;
    border-right: none;
    padding: 0 1.5rem;
    height: $element-height;
    @include topbar-filter-hover();
    cursor: pointer;

    .k-topBarDateMode &--selected#{&} {
      border-color: $action-color;
      border-right: 1px solid $action-color;
      border-width: 2px;

      + .k-topBarDateMode__mode {
        border-left: none;
      }
    }

    &:first-child {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }

    &:last-child {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      border-right: 1px solid $border-color;
    }
  }

  &__modeText {
    display: none;

    @include for-big-desktop-up() {
      display: inline-block;
    }
  }

  &__modeLength {
    display: inline-block;

    > i {
      font-size: 1.5rem;
      line-height: $element-height;
    }

    @include for-big-desktop-up() {
      display: none;
    }
  }
}