.k-checkbox {
  &--absoluteCenter {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    margin: auto 0;
    height: 20px;
  }

  &__input {
    display: none;
  }
  &__label {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    background: $white;
    border: 1px solid $light-gray;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      border-color: $blue;
    }
    &:active {
      border-color: $light-blue;
    }
  }
  input[type='checkbox']:checked + label {
    &:after {
      font-family: 'Material Icons';
      content: 'check';
      color: $blue;
      font-weight: bolder;
      font-size: $ts16;
      position: absolute;
      top: -2px;
      left: 1px;
      font-weight: 700;
    }
  }
  &--small{
    display: inline-block;
    float: left;
    margin-top: 1px;
    margin-right: 10px;
    .k-checkbox{
      &__label{
        width: 13px;
        height: 13px;
        margin: 0;
      }
    }
    input[type='checkbox']:checked + label {
      &:after {
        font-size: $ts12;
        left: 0px;
      }
    }
  }
}

.k-checkbox--padding {
  padding-left: 45px !important; // TODO: remove after table refactor
}
