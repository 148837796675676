$very-light-gray: #f7f7f7;
$lighter-gray: #eaeaea;
$light-gray: #dddddd;
$light-gray-80: #dddddd80;
$light-gray2: #cdd6de;
$mid-light-gray: #cccccc;
$gray: #aaaaaa;
$dark-gray: #454e57;
$mid-dark-gray: #eae8e4;
$darker-gray: #333333;

$light-orange: #ff7c1f;
$orange: #ff6b01;
$dark-orange: #e05e00;

$very-light-blue: #e6f8ff;
$light-blue: #20c3fe;
$blue: #06bbfe;
$dark-blue: #0186b7;
$darker-blue: #0d47a1;

$very-light-purple: #85669f;
$light-purple: #3a315e;
$purple: #272140;
$dark-purple: #0a0322;

$very-light-yellow: #fffaf0;
$light-yellow: #fcde0a;
$yellow: #fdce09;
$dark-yellow: #fcba0a;

$lighter-green: #e5ffc6;
$light-green: #9de000;
$green: #86bf00;
$dark-green: #567a00;

$lighter-red: #fbe9e7;
$light-red: #ff3333;
$red: #ff0000;
$dark-red: #b80000;
$darker-red: #520000;

$white: #fff;
$black: #333;
