$element-color: $white;
$border-color: $light-gray;
$hover-color: $very-light-gray;
$active-color: $light-gray;
$active-dropdown-color: $lighter-gray;
$text-color: $dark-purple;
$icon-color: $dark-purple;
$action-color: $orange;

$element-height: 3.5rem;

@mixin topbar-filter-hover {
  &:hover {
    background-color: $hover-color; 
  }
}

.k-topBarFilter {
  font-size: 1.5rem;
  font-weight: 500;
  color: $text-color;
}