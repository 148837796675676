$icomoon-font-family: "kadro-icons" !default;
$icomoon-font-path: "fonts" !default;

$k-icon-settings: "\e925";
$k-icon-suitcase: "\e924";
$k-icon-arrow_all: "\e900";
$k-icon-arrow_corner: "\e901";
$k-icon-arrow_double_circle: "\e902";
$k-icon-arrow_left: "\e903";
$k-icon-arrow_repeat: "\e904";
$k-icon-arrow_right: "\e905";
$k-icon-arrow_right_double: "\e906";
$k-icon-calendar: "\e907";
$k-icon-calendar_1: "\e908";
$k-icon-camera: "\e909";
$k-icon-clock: "\e90a";
$k-icon-control_point: "\e90b";
$k-icon-desktop: "\e90c";
$k-icon-done: "\e90d";
$k-icon-done_cover: "\e90e";
$k-icon-edit_cover: "\e90f";
$k-icon-email: "\e910";
$k-icon-file_double: "\e911";
$k-icon-inbox_done: "\e912";
$k-icon-list: "\e913";
$k-icon-lock_circle_close: "\e914";
$k-icon-money: "\e915";
$k-icon-next_arrow: "\e916";
$k-icon-phone_bluetooth_speaker: "\e917";
$k-icon-pin_sharp_circle: "\e918";
$k-icon-printer: "\e919";
$k-icon-profile: "\e91a";
$k-icon-profile_ok: "\e91b";
$k-icon-router_bottom: "\e91c";
$k-icon-search_right: "\e91d";
$k-icon-shuffle: "\e91e";
$k-icon-stats: "\e91f";
$k-icon-store_mall_directory: "\e920";
$k-icon-visibility: "\e921";
$k-icon-visibility_off: "\e922";
$k-icon-watch: "\e923";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.eot?n2xt6t');
  src:  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.eot?n2xt6t#iefix') format('embedded-opentype'),
    url('../#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?n2xt6t') format('truetype'),
    url('../#{$icomoon-font-path}/#{$icomoon-font-family}.woff?n2xt6t') format('woff'),
    url('../#{$icomoon-font-path}/#{$icomoon-font-family}.svg?n2xt6t##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.k-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  cursor: default;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &--clicky{
    cursor: pointer;
  }
}

.k-icon-settings {
  &:before {
    content: $k-icon-settings;
  }
}
.k-icon-suitcase {
  &:before {
    content: $k-icon-suitcase;
  }
}
.k-icon-arrow_all {
  &:before {
    content: $k-icon-arrow_all;
  }
}
.k-icon-arrow_corner {
  &:before {
    content: $k-icon-arrow_corner;
  }
}
.k-icon-arrow_double_circle {
  &:before {
    content: $k-icon-arrow_double_circle;
  }
}
.k-icon-arrow_left {
  &:before {
    content: $k-icon-arrow_left;
  }
}
.k-icon-arrow_repeat {
  &:before {
    content: $k-icon-arrow_repeat;
  }
}
.k-icon-arrow_right {
  &:before {
    content: $k-icon-arrow_right;
  }
}
.k-icon-arrow_right_double {
  &:before {
    content: $k-icon-arrow_right_double;
  }
}
.k-icon-calendar {
  &:before {
    content: $k-icon-calendar;
  }
}
.k-icon-calendar_1 {
  &:before {
    content: $k-icon-calendar_1;
  }
}
.k-icon-camera {
  &:before {
    content: $k-icon-camera;
  }
}
.k-icon-clock {
  &:before {
    content: $k-icon-clock;
  }
}
.k-icon-control_point {
  &:before {
    content: $k-icon-control_point;
  }
}
.k-icon-desktop {
  &:before {
    content: $k-icon-desktop;
  }
}
.k-icon-done {
  &:before {
    content: $k-icon-done;
  }
}
.k-icon-done_cover {
  &:before {
    content: $k-icon-done_cover;
  }
}
.k-icon-edit_cover {
  &:before {
    content: $k-icon-edit_cover;
  }
}
.k-icon-email {
  &:before {
    content: $k-icon-email;
  }
}
.k-icon-file_double {
  &:before {
    content: $k-icon-file_double;
  }
}
.k-icon-inbox_done {
  &:before {
    content: $k-icon-inbox_done;
  }
}
.k-icon-list {
  &:before {
    content: $k-icon-list;
  }
}
.k-icon-lock_circle_close {
  &:before {
    content: $k-icon-lock_circle_close;
  }
}
.k-icon-money {
  &:before {
    content: $k-icon-money;
  }
}
.k-icon-next_arrow {
  &:before {
    content: $k-icon-next_arrow;
  }
}
.k-icon-phone_bluetooth_speaker {
  &:before {
    content: $k-icon-phone_bluetooth_speaker;
  }
}
.k-icon-pin_sharp_circle {
  &:before {
    content: $k-icon-pin_sharp_circle;
  }
}
.k-icon-printer {
  &:before {
    content: $k-icon-printer;
  }
}
.k-icon-profile {
  &:before {
    content: $k-icon-profile;
  }
}
.k-icon-profile_ok {
  &:before {
    content: $k-icon-profile_ok;
  }
}
.k-icon-router_bottom {
  &:before {
    content: $k-icon-router_bottom;
  }
}
.k-icon-search_right {
  &:before {
    content: $k-icon-search_right;
  }
}
.k-icon-shuffle {
  &:before {
    content: $k-icon-shuffle;
  }
}
.k-icon-stats {
  &:before {
    content: $k-icon-stats;
  }
}
.k-icon-store_mall_directory {
  &:before {
    content: $k-icon-store_mall_directory;
  }
}
.k-icon-visibility {
  &:before {
    content: $k-icon-visibility;
  }
}
.k-icon-visibility_off {
  &:before {
    content: $k-icon-visibility_off;
  }
}
.k-icon-watch {
  &:before {
    content: $k-icon-watch;
  }
}
.k-icon-close {
  width: 1em;
  height: 1em;

  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 1em;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
.k-icon-warning {
  position: relative;
  color: $yellow;
  z-index: 5;

  &:before {
    content: "";
    position: absolute;
    top: 40%;
    left: 40%;
    width: 20%;
    height: 40%;
    background-color: $black;
    z-index: -1;
  }
}
