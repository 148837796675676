.k-app {
  position: relative;

  &__wrapper {
    position: relative;
    background-color: $very-light-gray;
    min-height: 950px;
    padding-top: 70px;
    > div {
      min-height: 100vh;
    }
  }

  @include for-tablet-landscape-up {
    &__wrapper {
      padding-top: 0;
    }
  }
}
