.k-topBarChoice {
  position: relative;
  margin: 0 0.9rem;
  cursor: pointer;
  height: 3.5rem;

  &--focused {
    .k-topBarChoice__list {
      display: block;
    }

    .k-topBarChoice__selected {
      background-color: $active-dropdown-color;
    }
  }

  &--jobTitleFilter {
    .k-topBarChoice__selectedIcon {
      font-size: 1.6rem;
      vertical-align: baseline;
    }
  }

  &--locationFilter,
  &--sortingFilter,
  &--jobTitleFilter,
  &--employmentConditionsFilter,
  &--nameFilter,
  &--multipleLocationFilter,
  &--multipleColumnFilter {
    .k-topBarChoice__selectedText {
      width: 11rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include for-large-desktop-up() {
        width: 12.5rem;
      }
    }
  }
  &--displayMode {
    width: auto;
    white-space: nowrap;
  }

  &--short {
    .k-topBarChoice__selectedText {
      display: none;

      @include for-desktop-up() {
        display: inline-block;
      }
    }
  }

  &--pinned-right {
    .k-topBarChoice__list {
      right: 0;
    }
  }

  &__selected {
    height: $element-height;
    line-height: $element-height;
    border: 1px solid $border-color;
    border-radius: 2px;
    background-color: $element-color;
    padding: 0 5px;
    min-width: 4rem;
    width: 100%;
    text-align: left;
    @include topbar-filter-hover();

    @include for-desktop-up() {
      display: inline-block;
      min-width: 18rem;
    }
  }

  &__selectedContent {
    display: flex;
  }

  &__selectedIcon {
    font-size: 2rem;
    margin-right: 0.5rem;
    vertical-align: sub;
  }

  &__arrowDown {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.7rem solid $black;
    transform: translateY(-50%);
  }

  &__list {
    display: none;
    position: absolute;
    min-width: 100%;
    background-color: $element-color;
    border: 1px solid $border-color;
    border-top: none;
    z-index: 150;
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__element {
    position: relative;
    display: flex;
    padding: 1.2rem 1rem;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid $border-color;
    white-space: nowrap;
    @include topbar-filter-hover();

    &--selected {
      .k-topBarChoice__elementCheckbox:after {
        border-color: $orange;
      }
    }
    &--singleSelect {
      border-color: $orange;
    }

    &--inactive {
      color: $gray;
    }

    &--paddingRight {
      padding-right: 50px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__elementCheckbox {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid $border-color;
    border-radius: 3px;

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 1px;
      width: 5px;
      height: 10px;
      border: solid 2px transparent;
      border-left: none;
      border-top: none;
      transform: translate(5.5px, 1px) rotate(45deg);
      -ms-transform: translate(5.5px, 2px) rotate(45deg);
    }
  }

  &__elementText {
    width: calc(100% - 28px);
    margin-left: 8px;
  }

  &--small {
    margin-top: auto;
    margin-bottom: auto;

    .k-topBarChoice__selected {
      min-width: 0;
      margin-bottom: 0;  
    }

    i {
      line-height: unset;
      color: #0a0322;
    }
  }
}

.k-topBar--schedule,
.k-topBar--payrollLocation {
  // Override styles for views with too many filters
  .k-topBarChoice__selected {
    min-width: 6rem;
  }
  .k-topBarChoice__selectedText {
    width: 4rem;
  }
  .k-topBarDisplayMode {
    .k-topBarChoice__selected {
      min-width: 16rem;
    }
    .k-topBarChoice__selectedText {
      width: 10rem;
    }
  }
}

// Css for stretching top bar fields on higher resolutions
@media (min-width: 1365px) {
  .k-topBarJobTitleFilter,
  .k-topBarNameFilter,
  .k-topBarMultipleColumnFilter,
  .k-topBarEmployeeFilter,
  .k-topBarEmploymentConditionsFilter,
  .k-topBarSortingFilter,
  .k-topBarLocationFilter,
  .k-topBarDisplayMode,
  .k-topBarMultipleLocationFilter {
    flex: 1 1 auto;
    max-width: 250px;
    min-width: 10em;
  }

  .k-topBar {
    .k-topBar__leftElements {
      flex-wrap: nowrap;
      max-width: calc(100% - 100px);
    }
  }

  .k-topBarChoice__selected {
    .k-topBarChoice__selectedContent {
      .k-topBarChoice__selectedText {
        width: 100%;
      }
    }
  }
  .k-topBar--schedule .k-topBarDisplayMode .k-topBarChoice__selected {
    min-width: 100px;
  }
}
