.k-topBarSearchInput {
  position: relative;

  &--filled {
    .k-topBarSearchInput__input {
      padding: 1rem 3rem 1rem 3.5rem;
    }

    .k-topBarSearchInput__closeIcon {
      display: block;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    font-size: 1.8rem;
  }

  &__input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 1rem 1rem 1rem 3.5rem;
  }

  &__closeIcon {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5rem;
    font-size: 1.8rem;

    .k-icon {
      display: block;
    }
  }
}