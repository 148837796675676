.k-EmptyState {
  &__content {
    box-sizing: border-box;
    padding: 20px 0;
    height: 50vh;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    &.dashboard {
      padding: 0;
      height: fit-content;
      background-color: transparent;
    }
  }

  &__contentContainer {
    box-sizing: border-box;
    height: 50vh;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex: auto;
    &.dashboard {
      padding: 0;
      height: fit-content;
      background-color: transparent;
    }
  }

  &__imageContainer img {
    max-width: 100%;
    height: 20vh;
    position: relative;
  }

  &__textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-gray;
  }

  &__header {
    font-weight: bold;
    color: $dark-gray;
  }

  &__link {
    color: $orange;
    cursor: pointer;
  }
}
