.input-group-addon {
  background-color: #fff;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}

.list-group-item--inactive {
  color: #aaa;
}

/* Validation */
label.error {
  color: #cc5965;
  display: inline-block;
  margin-left: 5px;
}

.form-control.error {
  border: 1px dotted #cc5965;
}
