.k-iconButton {
  color: $blue;
  float: right;
  position: relative;
  width: 44px;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;

  &:hover{
    color: $dark-blue;
  }

  span{
    font-size: 10px;
    position: absolute;
    text-transform: uppercase;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
