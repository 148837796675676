// fonts
$main-font: Lato;
$secondary-font: Helvetica, sans-serif;
$icons-font: "Material Icons";

// text size
$ts10: 10px;
$ts12: 12px;
$ts13: 13px;
$ts14: 14px;
$ts16: 16px;
$ts18: 18px;
$ts21: 21px;
$ts24: 24px;
$ts36: 36px;
$ts40: 40px;
$ts48: 48px;

// borders
$border-slim: 1px solid;
$border-thick: 4px solid;
$table-border: $border-slim $light-gray;

// paddigns
$small-padding: 5px 10px;

// stripes
$stripes-gradient: linear-gradient(
  135deg,
  rgba(255, 255, 255, 0.35) 25%,
  rgba(255, 255, 255, 0.05) 25%,
  rgba(255, 255, 255, 0.05) 50%,
  rgba(255, 255, 255, 0.35) 50%,
  rgba(255, 255, 255, 0.35) 75%,
  rgba(255, 255, 255, 0.05) 75%,
  rgba(255, 255, 255, 0.05) 100%
);
$stripes-size: 39.60px 39.60px;

// media-queries
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-landscape-max {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1500px) {
    @content;
  }
}
@mixin for-large-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

:root {
  font-size: 7.5px;

  @include for-tablet-portrait-up {
    font-size: 8px;
  }

  @include for-tablet-landscape-up {
    font-size: 8.5px;
  }

  @include for-desktop-up {
    font-size: 9px;
  }

  @include for-big-desktop-up {
    font-size: 10px;
  }
}
