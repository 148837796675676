/* Login Register Remind */

.loginscreen {
  &.middle-box {
    background: #0a0322 none repeat scroll 0 0;
    padding: 40px 60px 40px;
    width: 420px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0 0 4px 4px;
  }
}

.redux-toastr .toastr.error {
  box-shadow: none !important;
}

.copyright {
  color: #fff;
  display: block;
}
