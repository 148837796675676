.k-topBar {
  background-color: $white;
  display: flex;
  flex-direction: column-reverse;
  min-height: 70px;
  justify-content: space-between;

  &__notification{
    background: $orange;
    color: $white;
    height:  45px;
    padding: 0 20px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $white;
    
    .text{
      flex: 1;
      text-align: center;
    }

    .actions{
      display:flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;

      .kButton{
        border-radius:3px;
        border: 1px solid $white;
        margin-right:20px;
        transition: background-color 0.2s;
        height:25px;
      }

    }
  }

  &__leftElements {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  @include for-tablet-landscape-max {
    &__leftElements,
    &__rightElements {
      > * {
        padding: 10px 0;
      }
    }
  }

  @include for-tablet-portrait-up {
    &__leftElements {
      flex-basis: 100%;

      &--full {
        .k-calendarSwitch {
          &__move--left {
            margin-left: 0px;
          }
          &__move--right {
            margin-right: 30px;
          }
          &__backToToday {
            display: inline-block;
            margin: 0;
            margin-right: 30px;
          }
          &__changeMode {
            display: inline-block;
            margin-top: 0;
          }
        }
      }
    }
  }

  @include for-tablet-landscape-up {
    flex-direction: row;
    padding: 0 2.4rem;
    margin: 0 -1rem;

    &__rightElements {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
