.kmd-checkbox {
  margin: 1rem 0 3.5rem;

  &__label {
    position: relative;
    display: inline-block;
    color: rgba($black, 0.87);
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    z-index: 10;
    
    &:active .kmd-checkbox__input {
      opacity: 1;
      transform: scale(0);
      transition: opacity 0s, transform 0s;
    }
  }
  
  input.kmd-checkbox__input {
    display: block;
    position: absolute;
    top: -13px;
    left: -15px;
    width: 50px;
    height: 50px;
    margin: 0;
    z-index: -1;
    outline: none;
    background-color: rgba($black, 0.42);
    border-radius: 50%;
    opacity: 0;
    transform: scale(1);
    -ms-transform: scale(0); /* Graceful degradation for IE */
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: opacity 0.5s, transform 0.5s;
    
    &:checked {
      background-color: $orange;
      
      & + .kmd-checkbox__text:before {
        border-color: $orange;
        background-color: $orange;
      }
      
      & + .kmd-checkbox__text:after {
        border-color: $white;
      }
    }
    
    &:disabled {
      opacity: 0;
      
      & + .kmd-checkbox__text {
        cursor: initial;
        
        &:before {
          border-color: rgba($black, 0.26);
        }
      }
    }
    
    &:active {
      & + .kmd-checkbox__text:before {
        border-color: $orange;
      }
    }
    
    &:checked:active {
      & + .kmd-checkbox__text:before {
        border-color: transparent;
        background-color: rgba($black, 0.42);
      }
    }
  }
  
  &__text {
    font-weight: 400;

    &:before {
      content: "";
      display: inline-block;
      margin-right: 15px;
      border: solid 2px rgba($black, 0.42);
      border-radius: 2px;
      width: 20px;
      height: 20px;
      vertical-align: -5px;
      transition: border-color 0.5s, background-color 0.5s;
    }
    
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 2px;
      width: 5px;
      height: 10px;
      border: solid 2px transparent;
      border-left: none;
      border-top: none;
      transform: translate(5.5px, 1px) rotate(45deg);
      -ms-transform: translate(5.5px, 2px) rotate(45deg);
    }
  }
}

.material-checkbox > input:checked:disabled + span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.26);
}
