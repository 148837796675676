.k-translator-helper {
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 100%;
  line-height: 50px;
  text-align: center;
  border-top: 2px solid #333;
  background-color: #06bbfe;
  color: #fff;
  z-index: 999999;
}
