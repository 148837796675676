$padding-top: 2.5rem;
$padding-input-left: 4rem;

@mixin labelUp {
  top: 0;
  font-size: 1.6rem;
}

.kmd-textInput {
  position: relative;
  padding: $padding-top 0 5rem;

  &--disabled#{&} {
    padding: $padding-top 0 3rem;

    input {
      pointer-events: none;
      border-bottom: 1px solid transparent;
    }

    label {
      @include labelUp();
    }
  }

  &--error#{&} {
    label {
      @include labelUp();
      color: $red;
    }
  }

  &__error {
    color: $red;
    font-size: 1.4rem;
    margin-top: 5px;
    position: absolute;
    left: 5px;
  }

  &__bar {
    position: relative;
    display: block;

    &:before,
    &:after {
      content: '';
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: $orange;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }
    &:before {
      left: 50%;
    }
    &:after {
      right: 50%;
    }
  }

  input:focus ~ .kmd-textInput__bar:before, input:focus ~ .kmd-textInput__bar:after {
    width: 50%;
  }

  &__icon {
    position: absolute;
    top: calc(#{$padding-top} + 12px);
    right: $padding-input-left/2;
    font-size: 2rem;
  }

  input {
    font-size: 2.4rem;
    padding: 1rem $padding-input-left 1rem 2rem;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $dark-gray;

    &:focus {
      outline: none;
      border-bottom: 1px solid transparent;
    }
  }

  label {
    color: $darker-gray;
    font-size: 1.8rem;
    font-weight: 300;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: calc(#{$padding-top} + 10px);
    transition: 0.2s ease all;
  }

  input:focus ~ label,
  &--valid input ~ label {
    @include labelUp();
  }

  input:-webkit-autofill ~ label {
    @include labelUp();
  }
  
  input:-moz-autofill ~ label {
    @include labelUp();
  }
}
