$border-slim: 1px solid;
@mixin reverse-disable() {
  color: $dark-purple;
  background-color: $white;

  &.kButton--disabled {
    border: $border-slim $light-gray;

    &:hover {
      color: $dark-purple;
    }
  }
}

@mixin reverse-hover($color) {
  &:hover {
    color: $color;
    background-color: $very-light-gray;
  }
}

.kButton {
  border-radius: 2px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  height: 3.5rem;
  text-align: center;
  padding: 0 1.5rem;
  min-width: 100%;

  .material-icons {
    font-size: 2rem;
    vertical-align: sub;
  }

  .material-icons + span {
    margin-left: 5px;
    vertical-align: top;
  }

  &--inline {
    display: inline-block;
  }

  &#{&}--pushLeft {
    margin-right: auto;
  }

  &--floatleft {
    float: left;
  }

  &--floatright {
    float: right;
  }

  &--medium {
    min-width: 19rem;
  }

  &--small {
    min-width: 14rem;
  }

  &--fixedWidth {
    width: 20rem;
  }

  &--high {
    height: 4rem;
  }

  &--center {
    margin: 10px auto;
    display: block;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--blue {
    color: $white;
    background-color: $blue;
    border: $border-slim $blue;

    &:hover {
      background-color: $dark-blue;
      border: $border-slim $dark-blue;
    }
  }

  &--centerIcon {
    vertical-align: middle;
    i {
      margin: 0 10px;
      vertical-align: middle;
    }
    .material-icons + span {
      vertical-align: middle;
    }
  }

  &--red {
    color: $white;
    background-color: $light-red;
    border: $border-slim $light-red;

    &:hover {
      background-color: $red;
      border: $border-slim $red;
    }
  }

  &--orange {
    color: $white;
    background-color: $orange;
    border: $border-slim $orange;

    &:hover {
      background-color: $dark-orange;
      border: $border-slim $dark-orange;
    }
  }

  &--reverse-blue {
    @include reverse-disable();
    @include reverse-hover($blue);
    border: $border-slim $blue;
  }

  &--reverse-red {
    @include reverse-disable();
    @include reverse-hover($light-red);
    border: $border-slim $light-red;
  }

  &--reverse-orange {
    @include reverse-disable();
    @include reverse-hover($orange);
    border: $border-slim $orange;
  }

  &--purple-flip {
    border: $border-slim $purple;
    font-weight: 400;
    &:hover {
      color: $white;
      background-color: $purple;
    }
  }

  &--publish {
    background: $orange;
    color: $white;
    height: 70px;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    box-shadow: none;
    border-radius: 0;

    @include for-tablet-landscape-up {
      min-width: 100px;
    }

    &:hover {
      background: $dark-orange;
    }

    > span {
      margin-top: 1rem;
    }

    .send {
      line-height: 18px;
    }

    &.kButton--disabled {
      background-color: $lighter-gray;
      color: $orange;
      opacity: 1;
    }
  }

  &--disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
  &--disabled-gray {
    background: $gray;
    border: 1px solid $gray;
    color: white;
    pointer-events: none;
  }

  &--hide {
    display: none;
  }

  &__emoji {
    &--rocket {
      font-size: 18px;
      vertical-align: middle;
    }
  }

  &--radius {
    border-radius: 4px;
  }

  &--margin-left {
    margin-left: 15px;
  }
}

.k-buttonBar{
  .kButton.kButton--pushLeft,
  .pushLeft {
    margin-right: auto;
  }
}

.deleteButton {
  color: $red;
  font-size: $ts16;
  text-decoration: underline;
}
// old buttons to remove after refactor

// why export :(
.export {
  background-color: #ffffff;
  background-image: url("../img/share.png");
  background-position: left 26px center;
  background-repeat: no-repeat;
  border: $border-slim #06bbfe;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: $ts14;
  font-weight: 700;
  height: 30px;
  line-height: 14px;
  padding: 6px 0 0 15px;
  position: absolute;
  right: 50px;
  text-align: center;
  top: 20px;
  width: 120px;
  cursor: pointer;
  &:hover {
    color: #06bbfe;
  }
}

.add_blue {
  display: inline-block;
  margin-bottom: 10px;
}

.delete_selected {
  float: right;
}

.invite_employees {
  float: right;
  margin-right: 10px;
}

.mod_del {
  background-color: #e43a3a !important;
  border-color: #bc1a1a !important;
}

.btn-template-add {
  margin-top: 10px;
  text-align: center;
}
