@keyframes loader {
  0% {
    transform: rotate(20deg);
  }

  25% {
    transform: rotate(-20deg);
  }

  40% {
    transform: rotate(30deg);
  }

  50% {
    transform: rotate(-30deg);
  }

  60% {
    transform: rotate(60deg);
  }

  75% {
    transform: rotate(-60deg);
  }

  80% {
    transform: rotate(40deg);
  }

  95% {
    transform: rotate(-40deg);
  }

  100% {
    transform: rotate(20deg);
  }
}

.k-loader {
  background: url(../../../img/load1.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  left: 50%;
  position: fixed;
  top: 50%;
  margin: -50px 0 0 -50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 10000;

  &__tick {
    animation-name: loader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: 50% 76%;
  }

  &--unauthenticated {
    position: absolute;
  }

  &--logo {
    position: absolute;
    top: 20px;
    left: 19px;
    margin: 0;
    width: 30px;
    height: 30px;
    background-color: $dark-purple;
    transition: left 0.4s ease 0s;

    + .k-sideBar__logo {
      margin-left: 35px;
    }

    .k-loader__tick {
      width: 6px;
    }
  }
}

@include for-tablet-landscape-up {
  .k-sideBar--opened,
  .k-sideBar:hover {
    .k-loader--logo {
      left: 45px;
    }
  }

  .k-loader {
    &--logo {
      left: 19px;
    }
  }
}
