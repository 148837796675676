.kadro-select{
  width: 10vw;
min-width: 200px;
  .Select-control{
    border-radius: 0;
    height: 30px;
    .Select-multi-value-wrapper .Select-value{
      line-height: 30px;
    }
  }

  &.selected .Select-control, &.selected .Select-menu-outer{
    border-color: #06bbfe;
  }
  .Select-input{
    height: 30px;
  }
  .Select-placeholder{
    line-height: 30px;
  }
  .Select-arrow{
    border-color: #4a4a4a transparent transparent;
  }
  .Select-arrow{
    border-color: #4a4a4a transparent transparent;
  }
  .Select-arrow{
    border-color: #4a4a4a transparent transparent;
  }
  .Select-menu-outer{
    border-radius: 0;
    border-top: none;
    width:100%;
    max-height: 620px;
  }
  .Select-value-label{
    color: #454e57;
    line-height: 30px;
  }
  .is-selected{
    font-weight: bold;
  }
  .Select-menu{
    max-height: 600px;
  }
}

.selectInput {
  &__container {
    position: relative;
  }

  &__customTemplate{
     position: absolute;
     right: 16px;
     top: -7px;
     color: $blue;

  }
}
