.k-static {
  &__title {
    color: white;
    margin: 20px 0;
    text-align: center;
    font-size: 32px;
    margin-top: 100px;
    margin-bottom: 30px;
    font-weight: 600;
  }

  &__text {
    color: white;
    margin: 20px 0;
    text-align: center;
    font-weight: 300;
    line-height: 1.4;
    font-size: 20px;

    a {
      text-decoration: none;
      color: #06bbfe;
      font-weight: 400;
      margin-top: 10px;
      display: inline-block;

      &:hover {
        border-bottom: 1px solid #06bbfe;
        margin-bottom: -1px;

      }
    }
  }
}

.k-logoHeader {
  position: absolute;
  width: 100%;
  height: 110px;
  background: #0b0921;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #0b0921 0%, #060419 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #0b0921 0%, #060419 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #0b0921 0%, #060419 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=endColorstr='#060419', GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  top: 0;
  left: 0;
  padding: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid black;

  &__logo {
    display: block;
    height: 50px;
    margin: 0 auto;
  }
}
