$padding-input-left: 4rem;

.kmd-selectInput {
  position: relative;
  margin-bottom: 5rem;

  &__label {
    font-size: 1.6rem;
    margin-left: 5px;
  }

  &__input {
    position: relative;
    font-size: 2.4rem;
    padding: 1rem $padding-input-left 1rem 2rem;
    border-bottom: 1px solid $dark-gray;
    cursor: pointer;
  }

  &__icon {
    position: absolute;
    top: 17px;
    right: $padding-input-left/2;
    font-size: 1.8rem;
    text-align: center;
  }

  &__dropdown {
    display: none;
    position: fixed;
    list-style: none;
    width: 100%;
    padding: 0;
    background: $very-light-gray;
    font-size: 1.6rem;
    box-shadow: 0px 8px 20px 5px $light-gray;
    z-index: 100000;
    max-height: 30vh;
    overflow: auto;

    &--focused {
      display: block;
    }
  }

  &__option {
    position: relative;
    border-bottom: 1px solid $gray;
    padding: 1.5rem 5rem 1.5rem 2rem;
    cursor: pointer;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: $light-gray;
    }
  }

  &__optionChecked {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 1.8rem;
  }
}
